<template>
  <div class="tab-content" id="PTtab-2" :key="'PTtab-2'" >
    <div>
      <div class="pagenamPT">
        <h3 class="pagename2 w100w">하부파트너목록</h3>
      </div>
      <div class="sch_PC">
        <div class="PTsch flex-c">
          <div class="datesearchPTWarp flex-c gap-2">
            <h4>날짜</h4>
            <date-filter :retail="true" :id="'main-date-checkbox1'" :isOldYn="true"/>
          </div>
        </div>
      </div>
      <div class="sch_M">
        <div class="PTsch flex-c">
          <div class="datesearchM">
            <date-filter-mobile  :retail="true" :id="'main-date-checkbox1'" :isOldYn="true"/>
         </div>
        </div>
      </div>
      <div class="sch_Normal">
        <div class="sch_Btm">
          <div class="searchPTwrap">
            <div class="searchPT w30p">
              <div class="datesearchPT flex-c gap-2">
                <h4>구분</h4>
                <select class="h36px">
                  <option value="memId" selected>아이디</option>
                  <option value="memNick">닉네임</option>
                  <option value="recommenderId">상위유저</option>
                </select>
              </div>
            </div>
            <div class="searchPT">
              <input type="text" :placeholder="$t('front.search.emptySearch')" v-model="searchMemId" class="h36px"/>
              <a @click="getPartnerList(1)" class="sch_Icon">
                <img src="@/assets/img/search.png" alt=""/>
              </a>
            </div>
          </div>
          <div class="searchPTwrap">
            <div class="datesearchPT flex-c gap-2 w50p">
              <h4>정렬</h4>
              <select v-model="sort" class="h36px">
                <option value="all">전체</option>
                <option value="cashAmt_-1">보유머니 많은 순</option>
                <option value="cashAmt_1">보유머니 적은 순</option>
                <option value="pointAmt_-1">보유포인트 많은 순</option>
                <option value="pointAmt_1">보유포인트 적은 순</option>
                <option value="totalCashInAmt_-1">입금금액 많은 순</option>
                <option value="totalCashInAmt_1">입금금액 적은 순</option>
                <option value="totalCashOutAmt_-1">출금금액 많은 순</option>
                <option value="totalCashOutAmt_1">출금금액 적은 순</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="PTsch">
        <ul class="btBtnlist" :class="this.searchPartnerLevel">
          <li @click="onChangePartnerLevel('')">전체</li>
          <template v-for="item in partnerLevelList" :key="item.code">
            <template v-if="item.code !== 'NORMAL'">
              <li @click="onChangePartnerLevel(item.code)" :class="item.code">{{item.codeName}}</li>
            </template>
          </template>
        </ul>
      </div>
      <div class="strTablescr">
         <div class="strTablePC">
            <table class="strTablePT">
              <colgroup>
              </colgroup>
              <thead>
                <tr>
                  <th>아이디</th>
                  <th>닉네임</th>
                  <th>파트너등급</th>
                  <th>소속</th>
                  <th>롤링/루징<br>(카지노)</th>
                  <th>보유머니</th>
                  <th>보유포인트</th>
                  <th>입/출금액</th>
                  <th>입출손익</th>
                  <th>하부 생성</th>
                  <th>가입일시</th>
                </tr>
                <!-- <tr>
                  <th>아이디</th>
                  <th>닉네임</th>
                  <th>파트너등급</th>
                  <th>상위아이디/닉네임</th>
                  <th>카지노요율</th>
                  <th>호텔카지노요율</th>
                  <th>슬롯요율</th>
                  <th>보유금</th>
                  <th>보유포인트</th>
                  <th>하부 생성</th>
                  <th>가입일시</th>
                </tr>  -->
              </thead>
              <tbody>
                <template v-if="partnerList.length > 0">
                  <tr v-for="(item, index) in partnerList" :key="item.memId">
                    <td>{{item.memId}}</td>
                    <td :class="item.myLevel">{{item.memNick}}</td>
                    <td>
                       <template v-if="item.myLevelName === '총본사'">
                         <span class="box" :class="item.myLevel">대본</span>
                       </template>
                       <template v-else-if="item.myLevelName === '대본사'">
                         <span class="box" :class="item.myLevel">본사</span>
                       </template>
                       <template v-else-if="item.myLevelName === '부본사'">
                         <span class="box" :class="item.myLevel">부본</span>
                       </template>
                       <template v-else>
                         <span class="box" :class="item.myLevel">{{item.myLevelName}}</span>
                       </template>
                    </td>
                    <!-- <td>{{item.recommenderId}}/{{item.recommenderNick}}</td> -->
                    <td>
                      <div class="topwrap" v-if="item.topUserList && item.topUserList.length > 0 && item.topUserList !== '0'">
                        <select class="h20 upperUser">
                          <option v-for="pt in item.topUserList" :key="pt.recommenderId">
                              <span>[{{pt.partnerLevelName}}] </span>
                              <span>{{pt.recommenderId}}</span>
                          </option>
                        </select>
                        <i class="icon" @click="onOpenRate(item)">+</i>
                      </div>
                      <div v-else>
                        -
                      </div>
                    </td>
                    <td>
                      <div class="btn-input-wrap">
                        <span class="rate-font">{{item.pointRate}}%/{{item.loseRate}}%</span>
                        <a class="plus-btn" @click="onOpenRate(item)"></a>
                      </div>
                    </td>
                    <!-- <td>{{item.casinoRate}}</td>
                    <td>{{item.hcasinoRate}}</td>
                    <td>{{item.slotRate}}</td> -->
                    <td>
                      <div class="btn-input-wrap">
                        <span class="mw-73">{{thousand(item.cashAmt)}}</span>
                        <a class="inout-btn" v-if="item.myLevel === partnerLevelList[0].code" @click="onOpenPay(item, index)">지급회수</a>
                      </div>
                    </td>
                    <td>
                      <div class="btn-input-wrap">
                        <span class="mw-73">{{thousand(item.pointAmt)}}</span>
<!--                        <a class="inout-btn" @click="onOpenPoint(item, index)">지급회수</a>-->
                      </div>
                    </td>
                    <td>
                      <div class="twoline-wrap">
                        <span><em>입금</em>{{thousand(item.totalCashInAmt)}}</span>
                        <span><em>출금</em>{{thousand(item.totalCashOutAmt)}}</span>
                      </div>
                    </td>
                    <td>
                      <div :class="{ 'rdc' : item.totalCashInAmt - item.totalCashOutAmt > 0, 'blc' : item.totalCashInAmt - item.totalCashOutAmt < 0,}">
                        {{thousand(item.totalCashInAmt - item.totalCashOutAmt)}}
                      </div>
                    </td>
                    <td>
                      <div class="btn-input-wrap">
                        <button class="inout-btn color-btn-y" v-if="item.myLevel === partnerLevelList[0].code" @click="onChangePartnerAddPopupSub(item)">하부생성</button>
                      </div>
                      <!-- <div class="btn-input-wrap">
                        <button class="inout-btn color-btn" v-if="item.myLevel !== ''" @click="onChangePartnerAddPopupSub(item)">생성</button>
                      </div> -->
                    </td>
                    <td>{{dateFormatAll(item.joinDate)}}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="9">내역 없음</td>
                  </tr>
                </template>
              </tbody>
            </table>
         </div>
         <div class="strTableM">
            <div class="strTablePTM">
               <template v-if="partnerList.length > 0">
                 <ul v-for="(item, index) in partnerList" :key="item.memId">
                  <li>
                    <em>아이디</em>
                    <div><span>{{item.memId}}</span></div>
                  </li>
                  <li>
                    <em>등급</em>
                    <div>
                      <template v-if="item.myLevelName === '총본사'">
                        <span class="box" :class="item.myLevel">대본</span>
                      </template>
                      <template v-else-if="item.myLevelName === '대본사'">
                        <span class="box" :class="item.myLevel">본사</span>
                      </template>
                      <template v-else-if="item.myLevelName === '부본사'">
                        <span class="box" :class="item.myLevel">부본</span>
                      </template>
                      <template v-else>
                        <span class="box" :class="item.myLevel">{{item.myLevelName}}</span>
                      </template>
                    </div>
                  </li>
                  <li>
                    <em>닉네임</em>
                    <div :class="item.myLevel">{{item.memNick}}</div>
                  </li>
                   <li>
                    <em>소속</em>
                    <div class="topwrap" v-if="item.topUserList && item.topUserList.length > 0 && item.topUserList !== '0'">
                      <select class="h20 upperUser">
                        <option v-for="pt in item.topUserList" :key="pt.recommenderId">
                            <span>[{{pt.partnerLevelName}}] </span>
                            <span>{{pt.recommenderId}}</span>
                        </option>
                      </select>
                      <i class="icon">+</i>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </li>
                  <li>
                    <em>보유머니</em>
                    <div class="m-table">
                      <a class="inout-btn" v-if="item.myLevel === partnerLevelList[0].code" @click="onOpenPay(item, index)">지급회수</a>
                      <span>{{thousand(item.cashAmt)}}</span>
                    </div>
                  </li>
                  <li>
                    <em>롤링/루징<br>(카지노)</em>
                    <div class="m-table-r">
                      <span class="rate-font">{{item.pointRate}}%/{{item.loseRate}}%</span>
                      <a class="plus-btn" @click="onOpenRate(item)"></a>
                    </div>
                  </li>
                  <li>
                    <em>보유포인트</em>
                    <div class="m-table">
<!--                      <a class="inout-btn" @click="onOpenPoint(item, index)">지급회수</a>-->
                      <span>{{thousand(item.pointAmt)}}</span>
                    </div>
                  </li>
                  <li>
                    <em>하부생성</em>
                    <div class="m-table">
                      <button class="inout-btn color-btn" v-if="item.myLevel === partnerLevelList[0].code" @click="onChangePartnerAddPopupSub(item)">생성</button>
                    </div>
                  </li>
                  <li>
                    <em>입/출금액</em>
                    <div><!--column-->
                      <ul class="m-table-c">
                        <li>
                          <span>입금</span>
                          <span>{{thousand(item.totalCashInAmt)}}</span>
                        </li>
                        <li>
                          <span>출금</span>
                          <span>{{thousand(item.totalCashOutAmt)}}</span>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <em>가입 일시</em>
                    <div><span>{{dateFormatAll(item.joinDate)}}</span></div>
                  </li>
                  <li>
                    <em>입출차액</em>
                    <div>
                      <span :class="{ 'rdc' : item.totalCashInAmt - item.totalCashOutAmt > 0, 'blc' : item.totalCashInAmt - item.totalCashOutAmt < 0,}">{{thousand(item.totalCashInAmt - item.totalCashOutAmt)}}</span>
                    </div>
                  </li>
                  <li><em> </em> </li>
                 </ul>
               </template>
                <template v-else>
                 <ul>
                    <li class="nodata">내역 없음</li>
                 </ul>
               </template>
            </div>
         </div>
      </div>
      <pagination v-if="pageInfo"
                  :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  :className="'partnerPaging'"
                  @goToPage="getPartnerList"
      />
      <!------------------------------------------------------------------------------->
    </div>
    <div v-if="makesub" class="moveWrap2">
      <div class="tabmenu_make moveWraphead">
        <ul>
          <li @click="currentTab = 'makeTab1'" :class="{'current': currentTab == 'makeTab1'}" v-if="newPartner.partnerLevel !== 'PTN_5'">파트너</li>
          <li @click="currentTab = 'makeTab2'" v-if="isMemberMake(newPartner.partnerLevel)" :class="{'current': currentTab == 'makeTab2'}">회원</li>
        </ul>
        <a class="close" @click="onChangePartnerAddPopupSub"><img src="@/assets/img/icon_cancel.png" /></a>
      </div>
      <div class="tabcont_make moveWrapbody" v-if="currentTab == 'makeTab1' && newPartner.partnerLevel !== 'PTN_5'">
        <h4>파트너 생성</h4>
        <table class="part">
          <colgroup>
            <col width="20%"/>
            <col width="30%"/>
            <col width="20%"/>
            <col width="30%"/>
          </colgroup>
          <tr>
            <th>아이디</th>
            <td><input class="in" name="newMemId" type="text" v-model="newPartner.memId" autocomplete="off" role="presentation"/></td>
            <th>비밀번호</th>
            <td><input class="in" name="newMemPass" type="password" v-model="newPartner.memPass" autocomplete="off"/></td>
          </tr>
          <tr>
            <th>닉네임</th>
            <td><input class="in" type="text" v-model="newPartner.memNick" /></td>
            <th>출금 비밀번호</th>
            <td><input class="in password" pattern="[0-9]*" type="text" v-model="newPartner.cashOutPass" /></td>
          </tr>
          <tr>
            <th>추천인 아이디</th>
            <td>{{newPartner.recommenderId}}({{newPartner.partnerLevelName}})</td>
            <th>계좌정보</th>
            <td class="accountInfo">
              <bank-list :className="'in'" @onChange="onChangeBank"></bank-list>
              <input class="in w45w" type="text" v-model="newPartner.bankAcc"/>
            </td>
          </tr>
          <tr>
            <th>이름</th>
            <td><input class="in" type="text" v-model="newPartner.memName" /></td>
            <th>전화번호</th>
            <td><input class="in" type="text" v-model="newPartner.memPhone" /></td>
          </tr>
        </table>
        <h4>게임별 요율설정</h4>
        <table v-if="isWideScreen">
          <tr>
            <th :colspan="Object.keys(newPartner.rateGroup).length">{{$t('front.stributor.roll')}}(%)</th>
            <th :colspan="Object.keys(newPartner.rateGroup).length">{{$t('front.stributor.lose')}}(%)</th>
          </tr>
          <tr>
            <th v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
              {{$t(`front.gnb.${key}`)}}
            </th>
            <th v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
              {{$t(`front.gnb.${key}`)}}
            </th>
          </tr>
          <tr>
            <td v-for="(item) in newPartner.rateGroup" v-bind:key="item">
              <a class="rbnt">최대값 : {{item.maxPointRate}}</a><br>
              <a class="rbnt">최소값 : {{item.minPointRate}}</a>
            </td>
            <td v-for="(item) in newPartner.rateGroup" v-bind:key="item">
              <a class="rbnt">최대값 : {{item.maxLoseRate}}</a><br>
              <a class="rbnt">최소값 : {{item.minLoseRate}}</a>
            </td>
          </tr>
          <tr>
            <td v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
              <input type="text" v-model="item[`${key}PR`]">
            </td>
            <td v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
              <input type="text" v-model="item[`${key}LR`]">
            </td>
          </tr>
        </table>
        <ul class="rateset-m" v-if="!isWideScreen">
          <li class="table-name">롤링(%)</li>
          <li>
            <table>
              <tr v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                <td>{{$t(`front.gnb.${key}`)}}</td>
                <td>
                  <a class="rbnt">최대값 : {{item.maxPointRate}}</a><br>
                  <a class="rbnt">최소값 : {{item.minPointRate}}</a>
                </td>
                <td>
                  <input type="text" v-model="item[`${key}PR`]">
                </td>
              </tr>
            </table>
          </li>
          <li class="table-name">루징(%)</li>
          <li>
            <table>
              <tr v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                <td>{{$t(`front.gnb.${key}`)}}</td>
                <td>
                  <a class="rbnt">최대값 : {{item.maxLoseRate}}</a><br>
                  <a class="rbnt">최소값 : {{item.minLoseRate}}</a>
                </td>
                <td>
                  <input type="text" v-model="item[`${key}LR`]">
                </td>
              </tr>
            </table>
          </li>
        </ul>
        <a class="btn" @click="onAddPartnerSubmit">등록</a>
      </div>

      <div class="tabcont_make moveWrapbody" v-if="currentTab == 'makeTab2'">
        <h4>회원 생성</h4>
        <table class="part">
          <colgroup>
            <col width="20%"/>
            <col width="30%"/>
            <col width="20%"/>
            <col width="30%"/>
          </colgroup>
          <tr>
            <th>아이디</th>
            <td><input class="in" type="text" autocomplete="off" v-model="newMember.memId"/></td>
            <th>출금 비밀번호</th>
            <td><input class="in password" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" type="text" maxlength="4" pattern="[0-9]*" v-model="newMember.cashOutPass" inputmode="numeric"  /></td>
          </tr>
          <tr>
            <th>비밀번호</th>
            <td><input class="in" type="password" autocomplete="off" v-model="newMember.memPass"/></td>
            <th>전화번호</th>
            <td><input class="in" type="text" v-model="newMember.memPhone"/></td>
          </tr>
          <tr>
            <th>닉네임</th>
            <td><input class="in" type="text" v-model="newMember.memNick"/></td>
            <th>추천인 아이디</th>
            <td>{{newPartner.recommenderId}}({{newPartner.partnerLevelName}})
              <input class="in" type="hidden" v-model="newMember.recommenderId"/>
            </td>
          </tr>
          <tr>
            <th>이름</th>
            <td><input class="in" type="text" v-model="newMember.memName"/></td>
            <th>계좌정보</th>
            <td class="accountInfo">
              <bank-list :className="'in'" @onChange="onChangeBankMember"></bank-list>
              <input class="in w45w" type="text" v-model="newMember.bankacc" oninput="this.value = this.value.replace(/[^0-9.]/g, '')" />
            </td>
          </tr>
        </table>
        <a class="btn" @click="newMemberSignup()">등록</a>
      </div>
    </div>
  </div>
  <transition name="fade">
    <Paymodal v-if="PayMopen" :selectMem="selectMem" :selectIdx="selectIdx" @close="onClosePay"/>
  </transition>
  <transition name="fade">
    <PayPointmodal v-if="PayPMopen" :selectMem="selectMem" :selectIdx="selectIdx" @close="onClosePoint"/>
  </transition>
  <transition name="fade">
    <RateTable v-if="RateOpen" :selectMem="selectMem" @close="onCloseRate"/>
  </transition>
</template>

<script>
import {
  partnerLevels, retailMyPartnerList, partnerJoin, partnerRates, getRetailSummary
} from '@/api/retail'
import Pagination from '@/components/ui/Pagination.vue'
// import Paymodal from '@/components/common/Pay.vue'
// import PayPointmodal from '@/components/common/PayPoint.vue'
// import RateTable from '@/components/common/RateTable.vue'
import {
  isValidOnlyNumber,
  isValidOnlyPhoneNumber,
  isValidPassword,
  isValidPasswordPartner,
  isValidUserId,
  thousand
} from '@/libs/utils'
import { mapState } from 'vuex'
import BankList from '@/components/ui/BankList.vue'
import { PARTNER_LEVEL_NAME } from '@/libs/constants'
import { signUp } from '@/api/member'
import DateFilter from '@/components/ui/DateFilter'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import PayPointmodal from '@/components/common/PayPoint.vue'
import Paymodal from '@/components/common/Pay.vue'
import RateTable from '@/components/common/RateTable.vue'

export default {
  name: 'PartnerList',
  components: {
    RateTable,
    Paymodal,
    PayPointmodal,
    Pagination,
    BankList,
    DateFilter,
    DateFilterMobile
    // Paymodal,
    // PayPointmodal,
    // RateTable
  },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ]),
    partnerLevelName () {
      console.log(this.item.partnerLevel)
      return PARTNER_LEVEL_NAME[this.item.partnerLevel]
    }
  },
  data () {
    return {
      makesub: false,
      summary: {},
      rate: {},
      currentTab: 'makeTab1',
      searchType: 'P',
      searchPartnerLevel: '',
      partnerLevelList: [],
      partnerList: [],
      partnerLevelObject: null,
      summaryNew: null,
      searchMemId: '',
      myRate: {},
      myChildrenRate: [],
      newPartner: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        rateGroup: {}
      },
      newMember: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        memLevel: '1',
        partSendYn: 'N',
        firstBetConfYn: 'N',
        memStatus: 0
      },
      defaultNewPartner: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        rateGroup: {}
      },
      defaultMember: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        memLevel: '1',
        partSendYn: 'N',
        firstBetConfYn: 'N',
        memStatus: 0
      },
      rateMaxMinList: [],
      newPartnerLevel: {},
      PayMopen: false,
      PayPMopen: false,
      RateOpen: false,
      selectIdx: '',
      selectMem: {
        cashAmt: ''
      },
      sort: 'all',
      isWideScreen: window.innerWidth > 500
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.getPartnerLevels()
    this.getSummary()
    this.rateMaxMinList = await this.getPartnerRateForNewPartner()
    await this.getPartnerList(1)
    this.newPartnerLevel = this.partnerLevelList[0]
    this.emitter.emit('Loading', false)
    window.addEventListener('resize', this.updateScreenWidth)
  },
  unmounted () {
    window.removeEventListener('resize', this.updateScreenWidth)
  },
  watch: {
    sort () {
      if (this.sort !== 'all') {
        console.log(this.sort)
        const key = this.sort.split('_')[0]
        const order = this.sort.split('_')[1]

        this.partnerList.sort((a, b) => {
          let aValue = a[key].toString()
          let bValue = b[key].toString()

          if (aValue.indexOf(',') > -1) {
            aValue = aValue.replace(/,/, '')
          }

          if (bValue.indexOf(',') > -1) {
            bValue = bValue.replace(/,/, '')
          }

          aValue = Number(aValue)
          bValue = Number(bValue)

          return aValue > bValue ? Number(order) : 1 - Number(order) - 1
        })
      } else {
        this.getPartnerList(1)
      }
    },
    'newPartner.partnerLevel': function (newLevel) {
      if (newLevel === 'PTN_5') {
        this.currentTab = 'makeTab2'
      }
    }
  },
  methods: {
    updateScreenWidth () {
      this.isWideScreen = window.innerWidth > 500
    },
    onClosePay (newCash, idx) {
      if (newCash !== null) {
        this.partnerList[idx].cashAmt = newCash
      }
      this.PayMopen = false
      this.selectMem = null
    },
    onClosePoint (newPoint, idx) {
      if (newPoint !== null) {
        this.partnerList[idx].pointAmt = newPoint
      }
      this.PayPMopen = false
      this.selectMem = null
    },
    onOpenPay (selectMem, selectIdx) {
      this.selectIdx = selectIdx
      this.selectMem = {
        ...selectMem,
        cashAmt: thousand(selectMem.cashAmt)
      }
      console.log(selectMem)
      this.PayMopen = true
    },
    onOpenPoint (selectMem, selectIdx) {
      this.selectIdx = selectIdx
      this.selectMem = {
        ...selectMem
      }
      console.log(selectMem)
      this.PayPMopen = true
    },
    onOpenRate (selectMem) {
      this.selectMem = {
        ...selectMem
      }
      this.RateOpen = true
    },
    onCloseRate () {
      this.RateOpen = false
      this.selectMem = null
    },
    thousand,
    isMemberSignupValidate () {
      const data = this.newMember
      for (const key of Object.keys(data)) {
        const value = data[key]
        if (key === 'memId') {
          if (value === '') {
            this.onAlert('warningart', 'front.member.emptyMemId')
            return false
          }

          if (!isValidUserId(value)) {
            this.onAlert('warningart', 'api.U101')
            return false
          }
        }

        if (key === 'memPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemPass')
            return false
          }

          if (!isValidPassword(value) || value.length > 20) {
            this.onAlert('warningart', 'api.U102')
            return false
          }
        }

        if (key === 'cashOutPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }

          if (!isValidOnlyNumber(value) || value.length !== 4) {
            this.onAlert('warningart', 'api.U103')
            return false
          }
        }

        if (key === 'memNick') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemName')
            return false
          }

          if (value.length > 20 || value.length < 3) {
            this.onAlert('warningart', 'api.U105')
            return false
          }
        }

        if (key === 'memPhone') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemTel')
            return false
          }

          if (!isValidOnlyPhoneNumber(value)) {
            this.onAlert('warningart', 'front.member.noValidMemTel')
            return false
          }
        }

        if (key === 'recommenderId') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyRecommenderId')
            return false
          }
        }

        if (key === 'bank') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyBankName')
            return false
          }
        }

        if (key === 'memName') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyBankAccountName')
            return false
          }
        }

        if (key === 'bankacc') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyBankNumber')
            return false
          }

          if (!isValidOnlyNumber(value)) {
            this.onAlert('warningart', 'front.member.noValidBankNumber')
            return false
          }
        }
      }

      return true
    },
    newMemberSignup () {
      this.newMember.recommenderId = this.newPartner.recommenderId
      if (this.isMemberSignupValidate()) {
        this.newMember.memPhone = this.newMember.memPhone.toString()
        signUp(this.newMember).then(async response => {
          const result = response.data
          if (result.resultCode === '0') {
            await this.onCheck('front.member.completeSignup')
            this.makesub = false
            this.newMember = {
              ...this.defaultMember
            }
          } else {
            this.onAlert('warningart', 'api.' + result.resultCode)
          }
        }).catch(err => {
          console.error('singup err : ', err)
        })
      }
    },
    async onChangePartnerAddPopupSub (currentPartner) {
      console.log(currentPartner)
      if (!this.isMemberMake(currentPartner.myLevel)) {
        this.currentTab = 'makeTab1'
      }

      this.makesub = !this.makesub
      if (this.makesub) {
        console.log(this.rateMaxMinList)

        const list = await this.getPartnerRateForNewPartner(currentPartner.memId)
        console.log(list)

        this.newPartner.recommenderId = currentPartner.memId
        this.newPartner.partnerLevel = currentPartner.myLevel
        this.newPartner.partnerLevelName = currentPartner.myLevelName

        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const vendorCode = item.vendorCode
          let groupCode = this.commonCodeByCode[vendorCode].groupCode
          const groupCodeName = this.commonCodeByCode[vendorCode].groupCodeName
          if (groupCode === 'minigame') {
            groupCode = 'mini'
          }
          if (groupCode === 'casino' && groupCodeName.indexOf('HC-') > -1) {
            groupCode = 'hcasino'
          }
          if (groupCode) {
            this.newPartner.rateGroup[groupCode] = {
              ...item
            }
            this.newPartner.rateGroup[groupCode][`${groupCode}PR`] = '0.0'
            this.newPartner.rateGroup[groupCode][`${groupCode}LR`] = '0.0'
          }
          console.log(groupCode)
        }
      }
    },
    getPartnerRateForNewPartner (memId) {
      const params = {}
      if (memId) {
        params.memId = memId
      } else {
        params.memId = this.userData.memId
      }
      console.log('[REQ]/api/retail/partnerRates : ', params)
      return partnerRates(params).then(res => {
        console.log('[RES]/api/retail/partnerRates : ', res)
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.rateMaxMinList
          // this.rateMaxMinList = list
          return list
        }
      })
    },
    onChangePartnerLevel (partnerLevel) {
      this.searchPartnerLevel = partnerLevel
      this.getPartnerList(1)
    },
    getPartnerList (page) {
      if (!page) {
        page = this.pageInfo.page
      }
      const params = {
        page: page,
        searchMemId: this.searchMemId,
        partnerLevel: this.searchPartnerLevel,
        searchType: this.searchType,
        count_per_list: 40
      }
      console.log('[REQ]getRetailMyMemList : ', params)
      return retailMyPartnerList(params).then(res => {
        console.log('[RES]getRetailMyMemList : ', res.data)
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.list)
          this.partnerList = result.data.list
          this.pageInfo = result.data.pageInfo
        }
      })
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log('partnerLevel : ', result.data)
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]

          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          console.log(partnerObj)

          this.partnerLevelObject = partnerObj
        }
      })
    },
    onAddPartnerSubmit () {
      this.emitter.emit('Loading', true)
      const reqData = { ...this.newPartner, rateGroup: {} }

      for (const groupCode in this.newPartner.rateGroup) {
        const item = this.newPartner.rateGroup[groupCode]
        const pr = item[`${groupCode}PR`]
        if (pr) {
          if (Number(item.maxPointRate) < Number(pr) || Number(item.minPointRate) > Number(pr)) {
            this.onAlert('warningart', `front.stributor.${groupCode}RollingError`)
            this.emitter.emit('Loading', false)
            return false
          }
        } else {
          this.onAlert('warningart', `front.stributor.${groupCode}RollingError`)
          this.emitter.emit('Loading', false)
          return false
        }
        const lr = item[`${groupCode}LR`]
        if (lr) {
          if (Number(item.maxLoseRate) < Number(lr) || Number(item.minLoseRate) > Number(lr)) {
            this.onAlert('warningart', `front.stributor.${groupCode}LoseError`)
            this.emitter.emit('Loading', false)
            return false
          }
        } else {
          this.onAlert('warningart', `front.stributor.${groupCode}LoseError`)
          this.emitter.emit('Loading', false)
          return false
        }

        reqData.rateGroup[`${groupCode}PR`] = pr
        reqData.rateGroup[`${groupCode}LR`] = lr
      }

      if (!reqData.memId) {
        this.onAlert('warningart', 'front.member.emptyMemId')
        this.emitter.emit('Loading', false)
        return false
      }

      if (!isValidUserId(reqData.memId)) {
        this.onAlert('warningart', 'api.U101')
        this.emitter.emit('Loading', false)
        return false
      }

      if (!reqData.memPass) {
        this.onAlert('warningart', 'front.member.emptyMemPass')
        this.emitter.emit('Loading', false)
        return false
      }

      if (!isValidPasswordPartner(reqData.memPass) || reqData.memPass.length > 20) {
        this.onAlert('warningart', 'api.U102')
        this.emitter.emit('Loading', false)
        return false
      }

      if (!reqData.memNick) {
        this.onAlert('warningart', 'front.member.emptyMemNick')
        this.emitter.emit('Loading', false)
        return false
      }
      if (!reqData.cashOutPass) {
        this.onAlert('warningart', 'front.member.emptyCashOutPass')
        this.emitter.emit('Loading', false)
        return false
      }
      if (!reqData.bank) {
        this.onAlert('warningart', 'front.member.emptyBankName')
        this.emitter.emit('Loading', false)
        return false
      }
      if (!reqData.bankAcc) {
        this.onAlert('warningart', 'front.member.emptyBankNumber')
        this.emitter.emit('Loading', false)
        return false
      }
      if (!reqData.memName) {
        this.onAlert('warningart', 'front.member.emptyMemName')
        this.emitter.emit('Loading', false)
        return false
      }
      if (!reqData.memPhone) {
        this.onAlert('warningart', 'front.member.emptyMemTel')
        this.emitter.emit('Loading', false)
        return false
      }

      return partnerJoin(reqData).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.onCheck('하위파트너 생성신청이 완료되었습니다. 관리자에서 승인되면 가입처리가 완료됩니다.')
          this.onChangePartnerAddPopupSub()
          this.newPartner = { ...this.defaultNewPartner }
          this.getPartnerList(1)
          this.emitter.emit('Loading', false)
        } else {
          console.log(res)
          this.emitter.emit('Loading', false)
          this.onAlert('warningart', result.resultMessage)
        }
      })
    },
    onChangeBank (value) {
      this.newPartner.bank = value
    },
    onChangeBankMember (value) {
      this.newMember.bank = value
    },
    getSummary () {
      getRetailSummary({}).then(response => {
        const data = response.data
        if (data.resultCode === '0') {
          this.summary = data.data.summary
          this.summaryNew = data.data.summaryNew
        }
      })
    }
  }
}
</script>
<style scoped>
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
  } /* 위아래버튼 삭제 */
  .PTN_1 {color: #2b476e;}
  .PTN_2 {color: #363b72;}
  .PTN_3 {color: #1d505c;}
  .PTN_4 {color: #af2a2e;}
  .PTN_5 {color: #d98f00;}
  .NORMAL {color: #df663a;}
  .box { border-radius: 2px;font-size: 10px; color: #fff; border: 0.5px solid #000; width: 38px; height: 20px; line-height: 20px; text-align: center; box-sizing: border-box;}
  .PTN_1.box {background: #2b476e;}
  .PTN_2.box {background: #363b72;}
  .PTN_3.box {background: #1d505c;}
  .PTN_4.box {background: #af2a2e;}
  .PTN_5.box {background: #d98f00;}
  .NORMAL.box {background: #df663a;}
  .datesearchPT input[type="date"]::-webkit-calendar-picker-indicator{background: url(../../../assets/img/PTcalender.svg);display: block;background-repeat: no-repeat;background-size: contain;}
  .moveWrap2 {position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #fff;z-index: 5;font-family: 'NanumGothic';box-shadow: 0 0 10px 1px #ddd;}
  .moveWrap2 .close {position: absolute;right: 50px;top: 18px; cursor: pointer;}
  .moveWrap2 .close img {height: 35px;}
  .moveWraphead { padding: 20px 50px; background:#575C68; color: #fff; font-size: 18px; }
  .moveWrapbody { padding: 0 50px 20px; }
  .moveWrap2 h4 {font-size: 16px;font-weight: bold;margin: 40px 0 18px;position: relative;color: #575C68;}
  .moveWrap2 table {width: 800px;text-align: center;}
  .moveWrap2 th {height: 40px;background: #828793; border: solid 1px #DDDEE2;color: #fff;vertical-align: middle;}
  .moveWrap2 table tr:nth-child(2) th { background:#EEF0F5; color: #575C68; }
  .moveWrap2 .scroll table tr:nth-child(2) th { background:#828793; color: #fff;}
  .moveWrap2 td {height: 40px;background: #FBFCFD;vertical-align: middle;border: solid 1px #EEF0F5;white-space: nowrap;color: #575C68;overflow: hidden;text-overflow: ellipsis;position: relative;}
  .moveWrap2 input {width: 50px;border: 1px solid #0000F0;background: #fff;border-radius: 3px;color: #575C68;text-align: center;}
  .moveWrap2 input.in {width: 90%;height: 28px;}
  .moveWrap2 input.in.password {-webkit-text-security: disc;-moz-webkit-text-security: disc;-moz-text-security: disc;}
  .moveWrap2 .accountInfo input.in { width: 54%; }
  .moveWrap2 .accountInfo input.in.w45w {width: 45%;}
  .moveWrap2 select.in {width: 80px;height: 28px;border: 1px solid #60452a;background: #fff;color: #575C68;margin-right: 5px;}
  .moveWrap2 p {margin-top: 25px;color: #2A40B9;font-size: 12px;text-align: right;}
  .moveWrap2 a.btn {margin-top: 25px;background: #2F4DF2;font-size: 16px;padding: 0 55px;height: 35px;color: #fff;line-height: 35px;text-align: center;border-radius: 5px;display: inline-block;margin-bottom: 18px;float: right;}
  /* .tabmenu_make { width: } */
  .tabmenu_make > ul {display: flex; gap: 10px; }
  .tabmenu_make > ul li {border: 1px solid; border-radius: 10px; padding: 8px 15px; cursor: pointer; }
  .tabmenu_make > ul li.current {background: #fff;color: #575C68;}
  @media (max-width: 1000px) {
    .moveWrap2 {
      width: 90%;
    }
  }
  @media (max-width: 500px) {
    .moveWrap2 table {width: 100%;}
    .moveWrap2 .rateset-m td {height: 50px;}
    .table-name {background: #828793; color: #fff; padding: 10px; text-align: center;}
  }
</style>
<style scoped src="@/styles/striNew.css"></style>
